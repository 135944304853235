import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"

import SEO from "components/seo"
import ClientsCarousel from "components/clients"
import SubFooter from "components/sub-footer"
import { TeamAnimation } from "components/animations"
import CodeComparison from "components/code-comparison"
import Button from "components/button-2"

import { ArrowRightIcon } from "@primer/octicons-react"
import "mono-icons/iconfont/icons.css"

import CLI from "images/sourcery_review_screenshot.png"
import GithubFeedback from "images/github_feedback.png"
import VSCodeRefactoring from "images/vscode-refactoring.png"

const SourceryTeams = () => {
  return (
    <div>
      <SEO
        title="Sourcery | For Teams and Enterprises"
        description="Catch and fix quality issues before they enter your code base. Reduce bugs, increase velocity, and make your team happier."
        keywords={[
          `sourcery`,
          `refactoring`,
          `developer tools`,
          `python`,
          `features`,
          `cli`,
          `command line interface`,
          `teams`,
          `quality`,
          `velocity`,
          `availability`,
        ]}
      />

      {/* header start */}
      <div className="bg-black-500 py:20 lg:py-40 border-solid border-b border-[#000] overflow-y-hidden">
        <div className="flex items-center justify-center mx-auto overflow-x-hidden max-w-screen-2xl pt-6 overflow-y-hidden">
          <div className="w-full items-center mx-5 grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 lg:mr-0 gap-6 lg:gap-0 lg:px-16 overflow-y-hidden">
            <div className="flex flex-col justify-between items-start lg:items-start mt-24 col-span-1 space-y-7 lg:mt-0">
              <div className="px-6 py-4 rounded-full border-2 border-blue-200">
                <p className="text-blue-200 uppercase font-mono font-bold text-xs leading-[0.875rem]">
                  sourcery for teams
                </p>
              </div>
              <h1 className="text-3xl text-left text-white 2xl:text-6xl md:text-5xl lg:max-w-lg ">
                Faster development for your whole team
              </h1>
              <p className="text-base lg:text-xl text-black-200 text-left lg:max-w-md">
                Improve your team’s code quality during every step of the
                development journey - from writing the first line, to code
                review, to legacy maintenance.
              </p>
              <div className="flex gap-4 lg:gap-6 flex-col sm:flex-row">
                <Link to="/signup/?product=prod_team">
                  <Button
                    icon={<ArrowRightIcon size={16} />}
                    bgColor="bg-blue-200"
                    textColor="text-black-500"
                    iconColor="text-black-500"
                    animateIcon
                  >
                    Get Sourcery
                  </Button>
                </Link>

                <OutboundLink href="https://calendly.com/tim-gilboy/30min">
                  <Button
                    icon={<i className="mi-calendar font-bold" />}
                    bgColor="bg-black-200"
                    textColor="text-black-500"
                    iconColor="text-black-500"
                  >
                    Schedule Demo
                  </Button>
                </OutboundLink>
              </div>
            </div>
            <div className="mx-auto w-full col-span-1 max-w-xl pb-8 bg-hero-team h-full min-h-[400px]">
              <div className="flex flex-col justify-center items-center">
                {/* <img src={TeamHeroImage} /> */}
                <TeamAnimation />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* header end */}

      {/* customers banner start */}
      <div className="pt-16 pb-16 lg:pb-8 bg-black-500">
        <div className="max-w-screen-2xl mx-auto">
          <ClientsCarousel />
        </div>
      </div>
      {/* customers banner end */}

      <div className="bg-black-500 lg:py-5">
        <div className="max-w-screen-2xl mx-auto relative z-10">
          <div className="bg-white py-16 space-y-9 lg:py-[8rem] lg:mx-[2.5rem] rounded-2xl relative">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-6 lg:px-20 items-center">
              <div className="flex flex-col justify-center">
                <div className="flex mb-4">
                  <div className="mr-4">
                    <i className="mi-repeat text-pink-200 " />
                  </div>
                  <p className=" mt-1 ml-3 font-mono font-bold text-black-300 text-sm">
                    %timeit improvements
                  </p>
                </div>
                <div>
                  <p className="lg:max-w-[25rem] mb-4 font-body font-bold text-3xl text-black-500 leading-none">
                    Shift left with quality management
                  </p>
                  <p className="lg:max-w-[25rem] text-sm text-black-300 tracking-wide leading-6 mb-4 space-y-4">
                    <p>
                      Teams with low levels of tech debt are &gt;50% more
                      efficient than teams with high debt.
                    </p>
                    <p>
                      Capture and fix issues earlier in your development
                      lifecycle so your team doesn’t get slowed down by
                      technical debt.
                    </p>
                    <p>
                      Sourcery starts reviewing code in developers’ IDEs, checks
                      all changed code in CI, and can review all your legacy
                      code at once.
                    </p>
                  </p>
                </div>

                <Link to="/signup/?product=prod_team">
                  <Button
                    icon={<ArrowRightIcon size={16} />}
                    bgColor="bg-pink-100"
                    textColor="text-black-500"
                    iconColor="text-pink-500"
                    animateIcon
                  >
                    Get Sourcery for your team
                  </Button>
                </Link>
              </div>
              <img src={VSCodeRefactoring} className="z-10" />
            </div>

            {/* your best practices as code */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-6 lg:px-20 items-center">
              <div className="flex flex-col justify-center md:order-2">
                <div className="flex mb-4">
                  <div className="mr-4">
                    <i className="mi-repeat text-pink-200 " />
                  </div>
                  <p className=" mt-1 ml-3 font-mono font-bold text-black-300 text-sm">
                    old issue !={" "}
                    <span className="text-pink-200">new issue</span>
                  </p>
                </div>
                <div>
                  <p className="lg:max-w-[25rem] mb-4 font-body font-bold text-3xl text-black-500 leading-none">
                    Your best practices as code
                  </p>
                  <p className="lg:max-w-[25rem] text-sm text-black-300 space-y-4 tracking-wide leading-6 mb-4">
                    <p>Don’t let the same issues come up again and again</p>
                    <p>
                      Tell Sourcery your coding standards and it automatically
                      checks and fixes these issues across your whole code base
                    </p>
                  </p>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center">
                    <div
                      style={{ height: "32px", width: "32px" }}
                      className="bg-pink-100 text-pink-200 rounded-full flex justify-center items-center"
                    >
                      <i className="mi-bookmark" />
                    </div>
                    <p className="text-black-500 ml-4 tracking-tight leading-6">
                      Eliminate issues that keep coming up during code reviews
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div
                      style={{ height: "32px", width: "32px" }}
                      className="bg-pink-100 text-pink-200 rounded-full flex justify-center items-center"
                    >
                      <i className="mi-lock" />
                    </div>
                    <p className="text-black-500 ml-4 tracking-tight leading-6">
                      Teach new team members the way you code
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div
                      style={{ height: "32px", width: "32px" }}
                      className="bg-pink-100 text-pink-200 rounded-full flex justify-center items-center"
                    >
                      <i className="mi-expand" />
                    </div>
                    <p className="text-black-500 ml-4 tracking-tight leading-6">
                      Standardize your whole codebase and cut down on tech debt
                    </p>
                  </div>
                </div>
                <Link to="/signup/?product=prod_team">
                  <Button
                    icon={<ArrowRightIcon size={16} />}
                    bgColor="bg-pink-100 mt-4"
                    textColor="text-black-500"
                    iconColor="text-pink-500"
                    animateIcon
                  >
                    Get Sourcery for your team
                  </Button>
                </Link>
              </div>
              <CodeComparison
                options={[
                  {
                    name: "sourcery.yaml",
                    code: `
                        rules:
                        - id: filter-lambda-to-list-comprehension
                          pattern: |
                              list(filter(lambda \${arg}: \${expr}, \${items}))
                          replacement: |
                              [\${arg} for \${arg} in \${items} if \${expr}]
                          description: |
                              Use list comprehensions instead of filter and lambda
                    `,
                    language: "yaml",
                  },
                  {
                    name: "Before",
                    code: `
                        def show_awesome_spells(spellbook):
                            sourcery_spells = list(
                                filter(lambda spell: spell.is_awesome, spellbook)
                            )
                            print(sourcery_spells)
                    `,
                    language: "python",
                  },
                  {
                    name: "After",
                    code: `
                        def show_awesome_spells(spellbook):
                            sourcery_spells = [
                                spell for spell in spellbook if spell.is_awesome
                            ]
                            print(sourcery_spells)
                    `,
                    language: "python",
                  },
                ]}
              />
            </div>
            {/* end your best practices as code */}

            {/* fix issues everywhere in one command */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 px-6 lg:px-20 items-center">
              <div className="flex flex-col justify-start md:ml-8">
                <div className="flex items-center">
                  <div className="-mt-4 mr-4">
                    <i className="mi-compass text-orange-200" />
                  </div>
                  <p className="mb-4 ml-2 font-mono font-bold text-black-300 text-base">
                    sourcery <span className="text-orange-200">review</span>
                  </p>
                </div>
                <p className="lg:max-w-[25rem] mb-4 font-body font-bold text-3xl text-black-500 leading-none">
                  Fix issues everywhere in one command
                </p>
                <p className="lg:max-w-[25rem] text-sm text-black-300 tracking-wide leading-6">
                  Let Sourcery check for an issue across your entire codebase
                  and fix it everywhere. Whether you’re dealing with 500 or
                  500,000 lines of code, we’ve got you covered.
                </p>
                <Link to="/signup/?product=prod_team">
                  <Button
                    icon={<ArrowRightIcon size={16} />}
                    bgColor="bg-orange-100 mt-4"
                    textColor="text-black-500"
                    iconColor="text-orange-500"
                    animateIcon
                  >
                    Get Sourcery for your team
                  </Button>
                </Link>
              </div>
              <div className="flex flex-col justify-center items-center md:order-1">
                <img src={CLI} className="z-10 rounded-md" />
              </div>
            </div>
            {/* end fix issues everywhere in one command */}

            <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-none lg:gap-7 px-4 lg:px-20 lg:pt-20 rounded-sm">
              <div className=" bg-black-100 flex flex-wrap lg:flex-nowrap items-center justify-start py-7 md:pr-8 lg:col-span-2">
                <div className="lg:w-[80%] p-6 ">
                  <img
                    src={GithubFeedback}
                    className="z-10 object-cover rounded-sm lg:rounded-md"
                  />
                </div>
                <div className="px-6 mt-5">
                  <div className="text-black-300">
                    <i className="mi-bar-chart-alt w-6 h-6 " />
                  </div>
                  <p className="font-bold text-xl text-black-500 mt-4">
                    Set quality standards - without legacy issues
                  </p>
                  <p className="text-black-300 mt-4 space-y-4">
                    <p>
                      Sourcery in your CI keeps all your code to the same
                      standards of consistency and quality
                    </p>
                    <p>
                      Make sure all of your new code is up to par and doesn’t
                      contain violations
                    </p>
                    <p>
                      But, Sourcery in CI just checks changed code, so you won’t
                      be flooded with issues from legacy code
                    </p>
                  </p>
                  <OutboundLink href="https://docs.sourcery.ai/Guides/Getting-Started/CI/">
                    <Button
                      icon={<ArrowRightIcon size={16} />}
                      bgColor="bg-white mt-4"
                      textColor="text-black-500"
                      iconColor="text-black-300"
                      animateIcon
                    >
                      Read docs
                    </Button>
                  </OutboundLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SubFooter />
    </div>
  )
}

export default SourceryTeams
