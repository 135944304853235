import React from "react"
import PropTypes from "prop-types"
import Button from "components/button-2"
import { ArrowRightIcon } from "@primer/octicons-react"
import "mono-icons/iconfont/icons.css"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { CheckIcon } from "@primer/octicons-react"

const Card = ({ repoName, prNumber, prLink, commentsCount }) => {
  return (
    <a
      href={prLink}
      target="_blank"
      rel="noopener noreferrer"
      className="transform hover:scale-105 transition-transform duration-300 rounded-lg overflow-hidden shadow-lg hover:shadow-xl bg-white"
    >
      <div className="p-6 text-center flex flex-col items-center justify-between h-full">
        <p className="text-xs font-semibold uppercase tracking-wide text-gray-700">
          {repoName}
        </p>
        <p className="text-lg text-blue-600 font-bold my-2">#{prNumber}</p>
        <div className="flex items-center gap-2 my-2">
          <CheckIcon size={16} className="text-green-light" />
          <span className="text-green-500 text-sm font-medium">
            PR approved
          </span>
        </div>
        <p className="text-gray-600 text-sm">
          {commentsCount} comments from Sourcery
        </p>
      </div>
    </a>
  )
}

Card.propTypes = {
  repoName: PropTypes.string.isRequired,
  prNumber: PropTypes.number.isRequired,
  prLink: PropTypes.string.isRequired,
  commentsCount: PropTypes.number.isRequired,
}

export default function SubFooter() {
  return (
    <div className="bg-gray-100 p-6">
      <div className="text-center mb-5">
        <h2 className="text-2xl font-bold text-orange-light">
          See Sourcery in action
        </h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-7">
        <Card
          repoName="Pyatmo"
          prNumber="495"
          prLink="https://github.com/jabesq-org/pyatmo/pull/495"
          commentsCount={3}
        />
        <Card
          repoName="rtk-rnjn/algorithms"
          prNumber="77"
          prLink="https://github.com/rtk-rnjn/algorithms/pull/77"
          commentsCount={3}
        />
        <Card
          repoName="CPUT-DEVS/devpost-hackathon"
          prNumber="12"
          prLink="https://github.com/CPUT-DEVS/devpost-hackathon/pull/12"
          commentsCount={2}
        />
      </div>
      <div className="text-center md:max-w-[416px] mx-auto px-4 mb-4">
        <p className="text-xl font-medium text-black-300">
          Try Sourcery code reviews on all your PR&apos;s free for 14 days
        </p>
        <OutboundLink
          href="https://github.com/apps/sourcery-ai/installations/new"
          className="mt-6 mb-6 flex justify-center"
        >
          <Button
            icon={<ArrowRightIcon size={24} />}
            bgColor="bg-orange-light"
            textColor="text-white"
            iconColor="text-white"
          >
            Add to GitHub
          </Button>
        </OutboundLink>
      </div>
    </div>
  )
}
