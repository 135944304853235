import React from "react"
import { motion } from "framer-motion"
import { keyframes } from "styled-components"
import TeamAnimationIcon from "../images/team-animation-icon.svg"
import TeamAnimationCheckIcon from "../images/team-animation-check-icon.svg"
import { ArrowDownIcon, ArrowUpIcon } from "@primer/octicons-react"

const fadeInTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const fadeInBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const TeamAnimation = () => {
  const FloatingFile = (variant) => {
    const bgColor = variant.variant === "blue" ? "bg-blue-200" : "bg-[#494C53]"
    return (
      <motion.div>
        <div
          className={`w-[36px] h-[48px] ${bgColor} p-4 flex flex-col items-start rounded-sm`}
        >
          <div className={`w-[20px] h-2 bg-black-500 mb-3`} />
          <div className={`w-[20px] h-2 bg-black-500 mb-3`} />
          <div className={`w-[20px] h-2 bg-black-500 mb-3`} />
          <div className={`w-[16px] h-2 bg-black-500`} />
        </div>
      </motion.div>
    )
  }

  const floatingFilesPosition = [
    {
      y: "top-9",
      x: "left-[-2rem]",
      variant: "blue",
    },
    {
      y: "top-[-4rem]",
      x: "left-[1rem]",
    },
    {
      y: "top-[6rem]",
      x: "left-[8rem]",
    },
    {
      y: "top-[9.5rem]",
      x: "left-[18rem]",
    },
    {
      y: "top-[-4rem]",
      x: "left-[20rem]",
      variant: "blue",
    },
    {
      y: "top-[-6.5rem]",
      x: "left-[15rem]",
    },
    {
      y: "top-[-1.3rem]",
      x: "right-[7rem]",
    },
    {
      y: "top-[6rem]",
      x: "right-[12rem]",
    },
    {
      y: "top-[9rem]",
      x: "right-[4rem]",
      variant: "blue",
    },
  ]

  return (
    <motion.div className="w-full relative mx-auto">
      <div className="absolute top-40 left-7 lg:left-32 z-20">
        <motion.div
          className="w-full flex items-center justify-center relative"
          animate={{
            y: [0, -500],
            opacity: [1, 0],
          }}
          transition={{
            duration: 1,
            linear: "linear",
            delay: 2,
          }}
        >
          {/* floating card */}
          <motion.div
            animate={{
              y: [0, 15, 0],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              repeat: Infinity,
            }}
            className="z-20"
          >
            <div className="bg-blue-200 w-72 flex items-center rounded-sm p-5">
              <img src={TeamAnimationIcon} className="w-7" />
              <p className="font-mono ml-5 text-xs font-semibold leading-4 tracking-wide uppercase">
                46 REFACTORING IMPROVEMENTS found IN 80 files
              </p>
            </div>
          </motion.div>

          {floatingFilesPosition.map((data, index) => {
            return (
              <motion.div
                className={`absolute ${data?.y} ${data?.x} z-10`}
                animate={{
                  y: [0, -10, 0],
                }}
                transition={{
                  duration: 2,
                  ease: "easeInOut",
                  repeat: Infinity,
                  delay: index / 10,
                }}
                key={index}
              >
                <FloatingFile variant={data.variant} />
              </motion.div>
            )
          })}
        </motion.div>
      </div>
      <motion.div
        animate={{
          y: [0, 0, -500],
          times: [3, 3, 1],
          opacity: [0, 1, 0],
        }}
        transition={{
          linear: "linear",
          duration: 4,
          delay: 2.3,
        }}
        className="w-full absolute top-40 left-4 lg:left-8 z-20"
      >
        <motion.div
          animate={{
            y: [0, 15, 0],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            repeat: Infinity,
          }}
          className=""
        >
          <div className="bg-[#494C53] w-full lg:w-[28rem] flex flex-col items-start justify-center rounded-sm p-5">
            <p className="font-mono text-xs text-black-200 font-semibold leading-4 tracking-wider uppercase">
              Sourcery Bot - New pull request
            </p>
            <div className="flex w-full justify-between items-center">
              <p className="font-semibold text-white tracking-tight leading-6 mt-4 text-xl">
                Branch `V1.6` refactored by Sourcery
              </p>
              <p className="font-mono text-xs font-semibold leading-4 tracking-wider uppercase bg-blue-200 px-[.75rem] py-2 rounded-full mt-4">
                Open
              </p>
            </div>
            <p className="font-mono text-xs text-white font-semibold leading-4 tracking-wider uppercase mt-4">
              RequestING a code review from @OCTOCAT
            </p>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-3 h-[1.75rem] bg-black-300" />
          </div>
          <div className="bg-blue-200 w-[28rem] flex items-center rounded-sm p-5">
            <img src={TeamAnimationCheckIcon} className="w-7" />
            <p className="font-mono ml-5 text-xs font-semibold leading-4 tracking-wide uppercase">
              PULL REQUEST SUCCESSFULLY MERGED
            </p>
          </div>
        </motion.div>
      </motion.div>
      <motion.div
        animate={{
          y: [0, 0],
          opacity: [0, 1],
          times: [0, 2],
        }}
        transition={{
          delay: 6,
        }}
      >
        <motion.div
          animate={{
            y: [0, 15, 0],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            repeat: Infinity,
          }}
          className="w-full absolute top-40 left-4 lg:left-8 z-20"
        >
          <div className="bg-[#494C53] w-full lg:w-[28rem] flex flex-col items-start justify-center rounded-sm p-5">
            <p className="font-mono text-xs text-black-200 font-semibold leading-4 tracking-wider uppercase">
              Sourcery Bot Commented
            </p>
            <p className="font-semibold text-white tracking-tight leading-6 mt-4 text-xl">
              Code quality report
            </p>
          </div>
          <div className="bg-blue-200 w-full lg:w-[28rem] flex items-center gap rounded-sm p-5">
            <div className="w-1/3">
              <p className="font-mono text-[10px] lg:text-xs font-semibold leading-4 tracking-wide uppercase">
                complexity
              </p>
              <div className="flex items-center justify-start">
                <ArrowDownIcon size={24} />
                <p className="text-2xl ml-3">1.6%</p>
              </div>
            </div>
            <div className="w-1/3">
              <p className="font-mono text-[10px] lg:text-xs font-semibold leading-4 tracking-wide uppercase">
                Method length
              </p>
              <div className="flex items-center justify-start">
                <ArrowDownIcon size={24} />
                <p className="text-2xl ml-3">5%</p>
              </div>
            </div>
            <div className="w-1/3">
              <p className="font-mono text-[10px] lg:text-xs font-semibold leading-4 tracking-wide uppercase">
                Overall
              </p>
              <div className="flex items-center justify-start">
                <ArrowUpIcon size={24} />
                <p className="text-2xl ml-3">3.2%</p>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

const SignInAnimation = (props) => {
  return (
    <div {...props}>
      <div className="relative w-full h-screen overflow-hidden">
        <motion.div className="relative flex">
          {[
            { number: 0, duration: 13 },
            { number: 1, duration: 22 },
            { number: 0, duration: 15 },
            { number: 1, duration: 20 },
            { number: 0, duration: 17 },
            { number: 1, duration: 11 },
            { number: 0, duration: 19 },
            { number: 1, duration: 18 },
            { number: 0, duration: 21 },
            { number: 1, duration: 24 },
            { number: 0, duration: 14 },
            { number: 1, duration: 11 },
            { number: 0, duration: 19 },
            { number: 1, duration: 13 },
            { number: 1, duration: 8 },
            { number: 0, duration: 17 },
            { number: 1, duration: 23 },
          ].map((item, index) => (
            <motion.div
              className="w-[30px] h-[30px] mx-2 text-[#4fc3dc] text-xs"
              key={index}
              animate={{
                y: ["0vh", "100vh"],
              }}
              transition={{
                duration: 130 / item.duration,
                ease: "linear",
                repeat: Infinity,
              }}
            >
              {item.number}
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  )
}

export { fadeInTop, fadeInBottom, TeamAnimation, SignInAnimation }
